body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.link {
  color: #ff8640 !important;
  text-decoration: none !important;
}

.link:hover {
  text-decoration: underline !important;
}

.page-title {
  text-align: center;
  padding-top: 5%;
}

.rainbow-text {
  background: linear-gradient(53.13deg,
  #FFD33D 0%,
  #FB8532 19.02%,
  #EA4A5A 37.19%,
  #8A63D2 56.92%,
  #2188FF 79.93%,
  #34D058 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-color-dark {
  color: #445462;
}

.text-color-light {
  color: #555;
}

.terminal-text {
  font-family: monospace;
  text-align: left;
}

.bg-dark {
  background: #171819;
}

.bg-light {
  background: #bebebe;
}

body, #root {
  height: 100vh;
}

